import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import hubspotLogo from '../img/hubspot.png';

const Home = () => {
    const { logOut, user } = useUserAuth();
    const navigate = useNavigate();
    const install_url = "https://app-eu1.hubspot.com/oauth/authorize?client_id=d9620c02-e3ef-4a85-a43c-799500bbdd5c&redirect_uri=https://channel.erabond.io/auth-hubspot&scope=files.ui_hidden.read%20oauth%20files%20conversations.read%20conversations.custom_channels.read%20conversations.write%20conversations.custom_channels.write"
    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };
    return (
        <>
            <div style={{backgroundColor:"white", borderRadius: "8px"}}>
                <div style={{display: "flex", flexDirection: "column", color: "black", padding: "48px"}}>
                    <img src={hubspotLogo} width={200} style={{textAlign: "center", marginBottom: "24px", alignContent: "center"}}></img>
                    <h3>Connect Your HubSpot Account</h3>
                    <p>In order to access your HubSpot data, we will need you to connect the relevant permissions.</p>
                    <Button style={{backgroundColor: "#825ee7", color: "white"}} href={install_url} variant="primary">Connect HubSpot</Button>
                </div>
            </div>

        </>
    );
};

export default Home;